import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import axios from 'axios' // axios http请求库
axios.defaults.baseURL = 'https://cn.icainiao.net/a4g7cr1-c46e-78d5-9bfb-e302cde891bf' // 设置默认请求的url
Vue.prototype.$http = axios

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false

document.addEventListener('contextmenu', function(e) {
    e.preventDefault();
  });
document.addEventListener('keydown', function(e) {
    if (e.key === 'F12') {
      e.preventDefault();
    }
  });  

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')