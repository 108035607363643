import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    // 在state定义各数据，页面中可以用this.$store.state.data拿到data数据
    state: {
        data: 0
    },
    getters: {},
    // 在mutations中可修改数据， mutations接受state和调用时传来的params参数
    mutations: {
        // changeDataMut
        changeDataMut(state, params) {
            state.data = params
        }
    },
    actions: {},
    modules: {}
})