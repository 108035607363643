import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: "/",
        redirect: "/index"
    },
    {
        path: '/index',
        component: () => import('../views/index.vue'),
        redirect: '/home',
        children: [{
                path: '/home',
                component: () => import('../views/home.vue')
            },
            {
                path: '/charge',
                component: () => import('../views/charge.vue')
            },
            {
                path: '/spread',
                component: () => import('../views/spread.vue')
            },
            {
                path: '/help',
                component: () => import('../views/help.vue')
            }
        ]
    },
    {
        path: '/mobilehelp',
        component: () => import('../views/mobileHelp.vue')
    },
    {
        path: '/helpdetail',
        component: () => import('../views/helpDetail.vue')
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router